import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './store/reducers';
import { environment } from '@environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { GeneralDataModule } from '@general-data/general-data.module';

import { MatIconModule } from '@angular/material/icon';

import { ErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { HttpSpinnerInterceptor } from '@core/interceptors/http-spinner.interceptor';

import { CustomIconService } from './services/custom-icon.service';
import { RealTimeEventsService } from './services/real-time-events.service';
// import { GoogleMapsService } from './services/google-maps.service';

import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    // Connects RouterModule with StoreModule, uses MinimalRouterStateSerializer by default
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PreActivation,
    }),
    environment.production ? [] : StoreDevtoolsModule.instrument({ connectInZone: true }),
    GeneralDataModule,
    MatIconModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        CustomIconService,
        RealTimeEventsService,
        // StripeService,
        // StorageService,
        // SidebarContentService,
        // IntercomIntegrationService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: HttpFormatterInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: ClearCacheInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpSpinnerInterceptor,
          multi: true,
        },
      ],
    };
  }
}
