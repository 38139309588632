import { ChangeUserProfileSuccess, Logout } from '@auth/store/actions/auth.actions';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer, Action } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface State {
  router: RouterReducerState;
  [key: string]: unknown;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
};

export const localStorageSyncData = [{ auth: ['session'] }];

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return localStorageSync({
    keys: localStorageSyncData,
    rehydrate: true,
  })(reducer);
}

export function clearState(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State | undefined, action: Action): State => {
    if (action.type === Logout.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export function clearLoadedModules(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State | undefined, action: Action): State => {
    if (action.type === ChangeUserProfileSuccess.type) {
      if (state) {
        Object.keys(state).forEach((key: string) => {
          if (state && key !== 'router' && key !== 'auth' && key !== 'general-data') {
            state[key] = undefined;
          }
        });
      }
    }
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<State>> = [localStorageSyncReducer, clearState, clearLoadedModules];
