import { Injectable } from '@angular/core';
import { AuthCredentials } from '../interfaces/auth-credentials.interface';
import { Session } from '../interfaces/session.interface';
import { UserProfile } from '../interfaces/user-profile.interface';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public login(credentials: AuthCredentials): Observable<Session> {
    return this.http.post<Session>(`${environment.api}/services/user/login`, {
      username: credentials.email,
      password: credentials.password,
    });
  }

  public initForgotPassword(email: string): Observable<{ verificationSentTo: string }> {
    return this.http.post<{ verificationSentTo: string }>(`${environment.api}/services/user/reset-password/init`, {
      email,
      verification: 'sms',
    });
  }

  public verifyCode(email: string, code: string): Observable<{ resetPasswordToken: string }> {
    return this.http.post<{ resetPasswordToken: string }>(
      `${environment.api}/services/user/reset-password/verify-code`,
      { email, code }
    );
  }

  public resetPassword(password: string, resetPasswordToken: string): Observable<void> {
    return this.http.post<void>(`${environment.api}/services/user/reset-password`, { password, resetPasswordToken });
  }

  public getProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${environment.api}/services/cerebro/user/profile`);
  }

  public changeProfile(profile: Partial<UserProfile>): Observable<UserProfile> {
    return this.http.put<UserProfile>(`${environment.api}/services/cerebro/user/profile`, profile);
  }
}
