import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '../../interfaces/session.interface';
import { UserProfile } from '../../interfaces/user-profile.interface';
import { AuthService } from '../../services/auth.service';
import { State } from '../index';
import {
  Login,
  LoginSuccess,
  LoginFail,
  LoadProfile,
  LoadProfileSuccess,
  LoadProfileFail,
  Logout,
  ChangeUserProfile,
  ChangeUserProfileSuccess,
  ChangeUserProfileFail,
  InitForgotPassword,
  InitForgotPasswordSuccess,
  InitForgotPasswordFail,
  VerifyCode,
  VerifyCodeSuccess,
  VerifyCodeFail,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordFail,
} from '../actions/auth.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, tap, exhaustMap, mergeMap, switchMap, map } from 'rxjs/operators';
import Backendless from 'backendless';
import { LoadPermissions } from '@general-data/store/actions/data.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Login),
      exhaustMap((action) =>
        this.authService.login(action.credentials).pipe(
          mergeMap((session: Session) => {
            Backendless.LocalCache.set('user-token', session.userToken);
            return [LoginSuccess({ session }), LoadProfile()];
          }),
          catchError(({ error }) => {
            // console.log('LoginFail', error);
            return of(LoginFail({ error }));
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginSuccess),
        tap(() => {
          this.router.navigateByUrl('/app/home');
        })
      ),
    { dispatch: false }
  );

  initForgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitForgotPassword),
      map((action) => action.email),
      exhaustMap((email) =>
        this.authService.initForgotPassword(email).pipe(
          map(({ verificationSentTo }) => InitForgotPasswordSuccess({ verificationSentTo, email })),
          catchError(({ error }) => of(InitForgotPasswordFail({ error })))
        )
      )
    )
  );

  initForgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InitForgotPasswordSuccess),
        tap(({ email }) => {
          this.router.navigate(['/code-verification'], { queryParams: { email } });
        })
      ),
    { dispatch: false }
  );

  verifyCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyCode),
      // map((action) => action.code),
      exhaustMap(({ email, code }) =>
        this.authService.verifyCode(email, code).pipe(
          map(({ resetPasswordToken }) => VerifyCodeSuccess({ resetPasswordToken })),
          catchError(({ error }) => of(VerifyCodeFail({ error })))
        )
      )
    )
  );

  verifyCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VerifyCodeSuccess),
        tap(({ resetPasswordToken }) => {
          this.router.navigate(['/reset-password'], { queryParams: { resetPasswordToken } });
        })
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPassword),
      exhaustMap(({ password, resetPasswordToken }) =>
        this.authService.resetPassword(password, resetPasswordToken as string).pipe(
          map(() => ResetPasswordSuccess()),
          catchError(({ error }) => of(ResetPasswordFail({ error })))
        )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResetPasswordSuccess),
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    { dispatch: false }
  );

  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProfile),
      exhaustMap((_) =>
        this.authService.getProfile().pipe(
          switchMap((profile: UserProfile) => [LoadProfileSuccess({ profile }), LoadPermissions()]),
          catchError(({ error }) => {
            // console.log('error', error);
            return of(LoadProfileFail({ error }));
          })
        )
      )
    )
  );

  changeUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeUserProfile),
      map((action) => action.profile),
      exhaustMap((profile) =>
        this.authService.changeProfile(profile).pipe(
          map((profile: UserProfile) => ChangeUserProfileSuccess({ profile })),
          catchError(({ error }) => of(ChangeUserProfileFail({ error })))
        )
      )
    )
  );

  changeUserProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangeUserProfileSuccess),
        tap(() => {
          this.router.navigateByUrl('/app/home');
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Logout),
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<State>,
    private router: Router
  ) {}
}
